import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='./logo512.jpg' className="App-logo" alt="logo" width={100} height='100'/>
        <p>
          The New ERP Live is <b>Coming Soon</b>
        </p>
        <a
          className="App-link"
          href="https://timeglobalerp.com/"
          target="_blank"
        >
          Existing ERP
        </a>
      </header>
    </div>
  );
}

export default App;
